.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-row-r {
    flex-direction: row-reverse;
}

.flex-col {
    flex-direction: column;
}

.flex-col-r {
    flex-direction: column-reverse;
}

.flex-jc-start {
    justify-content: flex-start;
}

.flex-jc-end {
    justify-content: flex-end;
}

.flex-jc-cen {
    justify-content: center;
}

.flex-jc-sb {
    justify-content: space-between;
}

.flex-jc-sa {
    justify-content: space-around;
}

.flex-jc-se {
    justify-content: space-evenly;
}

.flex-ai-cen {
    align-items: center;
}