.sr-flight-hint-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    margin: 0.6rem 0.3rem;
}

.sr-flight-label {
    font-size: 0.8rem;
    margin: 0 0 0 0.5rem;
}

.sr-flight-date h2 {
    font-size: 1.3rem;
    margin: 0.3rem 0 0.8rem 1rem;
    font-weight: 400;
    margin: 0;
}

.sr-flight-date-and-price-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0.8rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--ion-color-light);
}

.sr-flight-price h2 {
    font-weight: 600;
    font-size: 1.8rem;
    text-align: right;
    margin: 0;
    color: var(--ion-color-primary);
}

.sr-flight-price p {
    color: var(--ion-color-primary);
    text-align: right;
    margin: 0;
}

.flightcard-border-bottom{
    border-bottom: 1px solid var(--ion-color-light);
}

.sr-flight-time-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.8rem;
    border-bottom: 1px solid var(--ion-color-light);
}

.sr-flight-plane-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 0.8rem 0.8rem 0.8rem;
}

.sr-flight-plane-container::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--ion-color-medium);
    position: absolute;
    top: calc(16px + 1rem);
    left: 0;
}

.sr-flight-plane {
    font-size: 2rem;
    margin-top: 16px;
    color: var(--ion-color-medium);
    position: relative;
    z-index: 2;
    padding: 0 0.3rem;
}

.sr-flight-plane::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: -1;
}

.sr-flight-duration,
.sr-flight-stops {
    color: var(--ion-color-medium);
    margin: 0;
    font-weight: 300;
}

.sr-flight-duration {
    margin-top: 0.5rem;
}

.sr-flight-time h3 {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0;
}

.sr-flight-time p {
    color: var(--ion-color-primary);
    font-weight: 300;
    margin-top: 0.3rem;
}

.sr-flight-sold-out-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.sr-flight-sold-out-overlay p {
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--ion-color-medium);
}

.sr-flight-price-smaller {
    font-weight: 300 !important;
}
