.sr-textbutton-title,
.sr-textbutton-icon {
    color: var(--ion-color-primary);
}

.sr-textbutton-title {
    margin-right: 0.2rem;
    font-size: 1rem;
}

.sr-textbutton-icon {
    font-size: 1.1rem;
}

.sr-textbutton-container {
    margin: 1rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
}

.sr-textbutton-title.end {
    margin-left: auto;
}
