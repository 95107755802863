/*******************************************************************************
** #
** #   /$$      /$$                               /$$          
** #  | $$$    /$$$                              |__/          
** #  | $$$$  /$$$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$ /$$$$$$$ 
** #  | $$ $$/$$ $$ |____  $$ /$$__  $$ /$$__  $$| $$| $$__  $$
** #  | $$  $$$| $$  /$$$$$$$| $$  \__/| $$  \ $$| $$| $$  \ $$
** #  | $$\  $ | $$ /$$__  $$| $$      | $$  | $$| $$| $$  | $$
** #  | $$ \/  | $$|  $$$$$$$| $$      |  $$$$$$$| $$| $$  | $$
** #  |__/     |__/ \_______/|__/       \____  $$|__/|__/  |__/
** #                                    /$$  \ $$              
** #                                   |  $$$$$$/              
** #                                    \______/               
** #
*******************************************************************************/
/*******************************************************************************
* Steps: 4px
*******************************************************************************/
/*******************************************************************************
* BOTTOM
*******************************************************************************/
.mb-safe {
    margin-bottom: var(--ion-safe-area-bottom);
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 4px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mb-3 {
    margin-bottom: 12px;
}

.mb-4 {
    margin-bottom: 16px;
}

.mb-5 {
    margin-bottom: 20px;
}

.mb-6 {
    margin-bottom: 24px;
}

.mb-7 {
    margin-bottom: 28px;
}

.mb-8 {
    margin-bottom: 32px;
}

.mb-9 {
    margin-bottom: 36px;
}

.mb-10 {
    margin-bottom: 40px;
}

/*******************************************************************************
* TOP
*******************************************************************************/
.mt-safe {
    margin-top: var(--ion-safe-area-top);
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 4px;
}

.mt-2 {
    margin-top: 8px;
}

.mt-3 {
    margin-top: 12px;
}

.mt-4 {
    margin-top: 16px;
}

.mt-5 {
    margin-top: 20px;
}

.mt-6 {
    margin-top: 24px;
}

.mt-7 {
    margin-top: 28px;
}

.mt-8 {
    margin-top: 32px;
}

.mt-9 {
    margin-top: 36px;
}

.mt-10 {
    margin-top: 40px;
}

.mt-15 {
    margin-top: 64px;
}

/*******************************************************************************
* LEFT
*******************************************************************************/
.ml-safe {
    margin-left: var(--ion-safe-area-start);
}

.ml-0 {
    margin-left: 0;
}

.ml-1 {
    margin-left: 4px;
}

.ml-2 {
    margin-left: 8px;
}

.ml-3 {
    margin-left: 12px;
}

.ml-4 {
    margin-left: 16px;
}

.ml-5 {
    margin-left: 20px;
}

.ml-6 {
    margin-left: 24px;
}

.ml-7 {
    margin-left: 28px;
}

.ml-8 {
    margin-left: 32px;
}

.ml-9 {
    margin-left: 36px;
}

.ml-10 {
    margin-left: 40px;
}

.ml-a {
    margin-left: auto;
}

/*******************************************************************************
* RIGHT
*******************************************************************************/
.mr-safe {
    margin-right: var(--ion-safe-area-end);
}

.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 4px;
}

.mr-2 {
    margin-right: 8px;
}

.mr-3 {
    margin-right: 12px;
}

.mr-4 {
    margin-right: 16px;
}

.mr-5 {
    margin-right: 20px;
}

.mr-6 {
    margin-right: 24px;
}

.mr-7 {
    margin-right: 28px;
}

.mr-8 {
    margin-right: 32px;
}

.mr-9 {
    margin-right: 36px;
}

.mr-10 {
    margin-right: 40px;
}

/*******************************************************************************
* VERTICAL
*******************************************************************************/
.mh-0 {
    margin-right: 0;
    margin-left: 0;
}

.mh-1 {
    margin-right: 4px;
    margin-left: 4px;
}

.mh-2 {
    margin-right: 8px;
    margin-left: 8px;
}

.mh-3 {
    margin-right: 12px;
    margin-left: 12px;
}

.mh-4 {
    margin-right: 16px;
    margin-left: 16px;
}

.mh-5 {
    margin-right: 20px;
    margin-left: 20px;
}

.mh-6 {
    margin-right: 24px;
    margin-left: 24px;
}

.mh-7 {
    margin-right: 28px;
    margin-left: 28px;
}

.mh-8 {
    margin-right: 32px;
    margin-left: 32px;
}

.mh-9 {
    margin-right: 36px;
    margin-left: 36px;
}

.mh-10 {
    margin-right: 40px;
    margin-left: 40px;
}

.mh-auto {
    margin-right: auto;
    margin-left: auto;
}

/*******************************************************************************
* HORIZONTAL
*******************************************************************************/
.mv-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mv-1 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mv-2 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mv-3 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.mv-4 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.mv-5 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mv-6 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.mv-7 {
    margin-top: 28px;
    margin-bottom: 28px;
}

.mv-8 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.mv-9 {
    margin-top: 36px;
    margin-bottom: 36px;
}

.mv-10 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.mv-auto {
    margin-top: auto;
    margin-bottom: auto;
}

/*******************************************************************************
* ALL
*******************************************************************************/
.ma-0 {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
}

.ma-1 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 4px;
}

.ma-2 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-left: 8px;
}

.ma-3 {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
    margin-left: 12px;
}

.ma-4 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 16px;
}

.ma-5 {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
}

.ma-6 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 24px;
    margin-left: 24px;
}

.ma-7 {
    margin-top: 28px;
    margin-bottom: 28px;
    margin-right: 28px;
    margin-left: 28px;
}

.ma-8 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-right: 32px;
    margin-left: 32px;
}

.ma-9 {
    margin-top: 36px;
    margin-bottom: 36px;
    margin-right: 36px;
    margin-left: 36px;
}

.ma-10 {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 40px;
    margin-left: 40px;
}


/*******************************************************************************
** #
** #   /$$$$$$$                 /$$       /$$ /$$                    
** #  | $$__  $$               | $$      | $$|__/                    
** #  | $$  \ $$ /$$$$$$   /$$$$$$$  /$$$$$$$ /$$ /$$$$$$$   /$$$$$$ 
** #  | $$$$$$$/|____  $$ /$$__  $$ /$$__  $$| $$| $$__  $$ /$$__  $$
** #  | $$____/  /$$$$$$$| $$  | $$| $$  | $$| $$| $$  \ $$| $$  \ $$
** #  | $$      /$$__  $$| $$  | $$| $$  | $$| $$| $$  | $$| $$  | $$
** #  | $$     |  $$$$$$$|  $$$$$$$|  $$$$$$$| $$| $$  | $$|  $$$$$$$
** #  |__/      \_______/ \_______/ \_______/|__/|__/  |__/ \____  $$
** #                                                        /$$  \ $$
** #                                                       |  $$$$$$/
** #                                                        \______/ 
** #
*******************************************************************************/
/*******************************************************************************
* Steps: 4px
*******************************************************************************/
/*******************************************************************************
* BOTTOM
*******************************************************************************/
.pb-safe {
    padding-bottom: var(--ion-safe-area-bottom) !important;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: 4px;
}

.pb-2 {
    padding-bottom: 8px;
}

.pb-3 {
    padding-bottom: 12px;
}

.pb-4 {
    padding-bottom: 16px;
}

.pb-5 {
    padding-bottom: 20px;
}

.pb-6 {
    padding-bottom: 24px;
}

.pb-7 {
    padding-bottom: 28px;
}

.pb-8 {
    padding-bottom: 32px;
}

.pb-9 {
    padding-bottom: 36px;
}

.pb-10 {
    padding-bottom: 40px;
}

/*******************************************************************************
* TOP
*******************************************************************************/
.pt-safe {
    padding-top: var(--ion-safe-area-top);
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: 4px;
}

.pt-2 {
    padding-top: 8px;
}

.pt-3 {
    padding-top: 12px;
}

.pt-4 {
    padding-top: 16px;
}

.pt-5 {
    padding-top: 20px;
}

.pt-6 {
    padding-top: 24px;
}

.pt-7 {
    padding-top: 28px;
}

.pt-8 {
    padding-top: 32px;
}

.pt-9 {
    padding-top: 36px;
}

.pt-10 {
    padding-top: 40px;
}

/*******************************************************************************
* LEFT
*******************************************************************************/
.pl-safe {
    padding-left: var(--ion-safe-area-start);
}

.pl-0 {
    padding-left: 0;
}

.pl-1 {
    padding-left: 4px;
}

.pl-2 {
    padding-left: 8px;
}

.pl-3 {
    padding-left: 12px;
}

.pl-4 {
    padding-left: 16px;
}

.pl-5 {
    padding-left: 20px;
}

.pl-6 {
    padding-left: 24px;
}

.pl-7 {
    padding-left: 28px;
}

.pl-8 {
    padding-left: 32px;
}

.pl-9 {
    padding-left: 36px;
}

.pl-10 {
    padding-left: 40px;
}

/*******************************************************************************
* RIGHT
*******************************************************************************/
.pr-safe {
    padding-right: var(--ion-safe-area-end);
}

.pr-0 {
    padding-right: 0;
}

.pr-1 {
    padding-right: 4px;
}

.pr-2 {
    padding-right: 8px;
}

.pr-3 {
    padding-right: 12px;
}

.pr-4 {
    padding-right: 16px;
}

.pr-5 {
    padding-right: 20px;
}

.pr-6 {
    padding-right: 24px;
}

.pr-7 {
    padding-right: 28px;
}

.pr-8 {
    padding-right: 32px;
}

.pr-9 {
    padding-right: 36px;
}

.pr-10 {
    padding-right: 40px;
}

/*******************************************************************************
* VERTICAL
*******************************************************************************/
.pv-0 {
    padding-right: 0;
    padding-left: 0;
}

.pv-1 {
    padding-right: 4px;
    padding-left: 4px;
}

.pv-2 {
    padding-right: 8px;
    padding-left: 8px;
}

.pv-3 {
    padding-right: 12px;
    padding-left: 12px;
}

.pv-4 {
    padding-right: 16px;
    padding-left: 16px;
}

.pv-5 {
    padding-right: 20px;
    padding-left: 20px;
}

.pv-6 {
    padding-right: 24px;
    padding-left: 24px;
}

.pv-7 {
    padding-right: 28px;
    padding-left: 28px;
}

.pv-8 {
    padding-right: 32px;
    padding-left: 32px;
}

.pv-9 {
    padding-right: 36px;
    padding-left: 36px;
}

.pv-10 {
    padding-right: 40px;
    padding-left: 40px;
}

/*******************************************************************************
* HORIZONTAL
*******************************************************************************/
.ph-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.ph-1 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.ph-2 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.ph-3 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.ph-4 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.ph-5 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ph-6 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.ph-7 {
    padding-top: 28px;
    padding-bottom: 28px;
}

.ph-8 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.ph-9 {
    padding-top: 36px;
    padding-bottom: 36px;
}

.ph-10 {
    padding-top: 40px;
    padding-bottom: 40px;
}

/*******************************************************************************
* ALL
*******************************************************************************/
.pa-0 {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
}

.pa-1 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 4px;
}

.pa-2 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
}

.pa-3 {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    padding-left: 12px;
}

.pa-4 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
}

.pa-5 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.pa-6 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
}

.pa-7 {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-right: 28px;
    padding-left: 28px;
}

.pa-8 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
    padding-left: 32px;
}

.pa-9 {
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 36px;
    padding-left: 36px;
}

.pa-10 {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
}


/*******************************************************************************
** #
** #    /$$$$$$  /$$                           /$$             /$$              
** #   /$$__  $$| $$                          | $$            | $$              
** #  | $$  \ $$| $$$$$$$   /$$$$$$$  /$$$$$$ | $$ /$$   /$$ /$$$$$$    /$$$$$$ 
** #  | $$$$$$$$| $$__  $$ /$$_____/ /$$__  $$| $$| $$  | $$|_  $$_/   /$$__  $$
** #  | $$__  $$| $$  \ $$|  $$$$$$ | $$  \ $$| $$| $$  | $$  | $$    | $$$$$$$$
** #  | $$  | $$| $$  | $$ \____  $$| $$  | $$| $$| $$  | $$  | $$ /$$| $$_____/
** #  | $$  | $$| $$$$$$$/ /$$$$$$$/|  $$$$$$/| $$|  $$$$$$/  |  $$$$/|  $$$$$$$
** #  |__/  |__/|_______/ |_______/  \______/ |__/ \______/    \___/   \_______/
** #
*******************************************************************************/
/*******************************************************************************
* Steps: 4px
*******************************************************************************/
/*******************************************************************************
* LEFT
*******************************************************************************/
.l-0 {
    left: 0;
}
.l-1 {
    left: 4px;
}
.l-2 {
    left: 8px;
}
.l-3 {
    left: 12px;
}
.l-4 {
    left: 16px;
}
.l-5 {
    left: 20px;
}
.l-6 {
    left: 24px;
}
.l-7 {
    left: 28px;
}
.l-8 {
    left: 32px;
}
.l-9 {
    left: 36px;
}
.l-10 {
    left: 40px;
}

/*******************************************************************************
* RIGHT
*******************************************************************************/
.r-0 {
    right: 0;
}
.r-1 {
    right: 4px;
}
.r-2 {
    right: 8px;
}
.r-3 {
    right: 12px;
}
.r-4 {
    right: 16px;
}
.r-5 {
    right: 20px;
}
.r-6 {
    right: 24px;
}
.r-7 {
    right: 28px;
}
.r-8 {
    right: 32px;
}
.r-9 {
    right: 36px;
}
.r-10 {
    right: 40px;
}

/*******************************************************************************
* TOP
*******************************************************************************/
.t-0 {
    top: 0;
}
.t-1 {
    top: 4px;
}
.t-2 {
    top: 8px;
}
.t-3 {
    top: 12px;
}
.t-4 {
    top: 16px;
}
.t-5 {
    top: 20px;
}
.t-6 {
    top: 24px;
}
.t-7 {
    top: 28px;
}
.t-8 {
    top: 32px;
}
.t-9 {
    top: 36px;
}
.t-10 {
    top: 40px;
}

/*******************************************************************************
* BOTTOM
*******************************************************************************/
.b-0 {
    bottom: 0;
}
.b-1 {
    bottom: 4px;
}
.b-2 {
    bottom: 8px;
}
.b-3 {
    bottom: 12px;
}
.b-4 {
    bottom: 16px;
}
.b-5 {
    bottom: 20px;
}
.b-6 {
    bottom: 24px;
}
.b-7 {
    bottom: 28px;
}
.b-8 {
    bottom: 32px;
}
.b-9 {
    bottom: 36px;
}
.b-10 {
    bottom: 40px;
}