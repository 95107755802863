ion-content {
    --padding-start: 1.2rem;
    --padding-end: 1.2rem;
    --padding-top: 1.2rem;
    --padding-bottom: 1.2rem;
}

ion-modal {
    --height: auto;
}

ion-segment {
    width: 100%;
    margin: 0 0.8rem;
}

ion-title {
    padding: 0 0.8rem;
}

ion-icon.rotate-180 {
    transform: rotate(180deg);
}

ion-item {
    --padding-start: 0;
}

p,
h1,
h2,
h3 {
    color: #666;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

.sdr-ripple-color-primary {
    color: var(--ion-color-primary-ripple);
}

.sdr-ripple-color-light {
    color: #ffffff;
}

.sdr-text-color {
    color: #666 !important;
}

.sdr-text-color-white {
    color: #FFF !important;
}

.sdr-text-color-primary {
    color: var(--ion-color-primary) !important;
}

.sdr-text-color-medium {
    color: var(--ion-color-medium);
}

.sdr-text-color-warning {
    color: var(--ion-color-danger) !important;
}

.sdr-text-small {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
}

.sdr-text-normal {
    font-size: 1rem !important;
}

.sdr-text-biggernormal {
    font-size: 1.2rem !important;
}

.sdr-text-large {
    font-size: 1.4rem !important;
}

.sdr-text-extra-large {
    font-size: 1.7rem !important;
}

.sdr-text-giant {
    font-size: 2rem !important;
}

.sdr-text-underline{
    text-decoration: underline;
}

.sdr-text-error{
    color: var(--ion-color-danger);
}

.sdr-letter-spacing-closer {
    letter-spacing: -0.03rem;
}

.sdr-line-height-normal {
    line-height: 1;
}

.sdr-margin-top-small {
    margin-top: 0.6rem;
}

.sdr-margin-top-medium {
    margin-top: 1.2rem;
}

.sdr-margin-top-big {
    margin-top: 1.8rem;
}

.sdr-price-item-label {
    font-weight: 500;
}

.sdr-total-price-label {
    font-size: 1.2rem;
    margin-bottom: 0;
}

.sdr-total-price-price {
    font-size: 1.4rem;
}

.sdr-total-price-price {
    text-align: right;
    color: var(--ion-color-primary);
}

.sdr-font-family{
    font-family: var(--ion-font-family);
}

.sdr-font-weight-400 {
    font-weight: 400;
}

.sdr-font-weight-500 {
    font-weight: 500 !important;
}

.sdr-font-weight-600 {
    font-weight: 600 !important;
}

.sdr-font-weight-light {
    font-weight: 300;
}

.sdr-item-note {
    font-size: 1.5rem;
    font-weight: 200;
    color: var(--ion-color-primary);
}

.sdr-total-price-container {
    display: flex;
    align-items: baseline;
}

.sdr-background-light {
    background: var(--ion-color-light);
}

.sdr-background-primary-shade {
    background: #faf0f5;
}

.sdr-half-margin{
    margin: 8px !important;
}

.sdr-half-margin-vertical{
    margin: 8px 0 !important;
}

.sdr-half-margin-horizontal{
    margin: 0 8px !important;
}

.sdr-spinner{
    --background: url("/assets/spinner/spinner.gif"), #FFFFFF;
    --width: 96px;
    --height: 96px;
}

.networkConnected{
    border: none;
}

.networkNotConnected{
    border: 3px solid var(--ion-color-danger);
}






.Toastify__toast-container {
    z-index: 200005 !important;
}

.renewed-toast-toast{
    padding: 0 16px !important;
}

.renewed-toast-toast:first-of-type{
    padding-top: var(--ion-safe-area-top) !important;
}

.Toastify__toast--error{
    background: var(--ion-color-danger) !important;
}

.Toastify__toast--warning{
    background: var(--ion-color-warning) !important;
}




.sdr-font-smaller {
    font-size: 14px;
}

.sdr-font-bigger {
    font-size: 21px;
}

.sdr-currency-popover{
    --width: 80%;
    --max-width: 300px;
}

.sdr-addflightmodal-popover{
    --width: 80%;
    --max-width: 300px;
}

.sdr-padding-half {
    padding: 8px;
}


[popover].popover-viewport {
    display: initial;
    position: initial;
    margin: initial;
    border: initial;
    background: initial;
    padding: initial;
    width: initial;
    height: initial;
    overflow: initial;
    inset: initial;
    color: initial;
}